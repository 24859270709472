const SOCIAL_LINKS = [
  // {
  //   icon: 'facebook',
  //   title: 'facebook',
  //   to: 'https://www.facebook.com/mosprom.eng',
  // },
  // {
  //   icon: 'instagram',
  //   title: 'instagram',
  //   to: 'https://www.instagram.com/mosprom/',
  // },
  {
    icon: 'youtube-play',
    title: 'youtube-play',
    to: 'https://www.youtube.com/channel/UCSzmCvVznNyCbH7r3rYVBug',
  },
  {
    icon: 'telegram',
    title: 'telegram',
    to: 'https://t.me/exportmoscow',
  },
];

export default SOCIAL_LINKS;
