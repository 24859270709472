export const CONTACTS_VALUES = {
  phones: {
    // main_old: {
    //   value: '+74955266574',
    //   text: '+7 (495) 526-65-74',
    //   adds: '300',
    // },
    main: {
      value: '+74956200000',
      smi: '+74956200000,39779',
    },
  },
  emails: {
    main: {
      value: 'info@prom.moscow',
      text: 'info@prom.moscow',
    },
    smi: {
      value: 'pr@prom.moscow',
      text: 'pr@prom.moscow',
    },
  },
  addrs: {
    // main_old: {
    //   postal: '125009',
    //   contry: 'Россия',
    //   city: 'г. Москва',
    //   street: 'Большой Кисловский переулок, дом 6',
    // },
    main: {
      postal: '123112',
      contry: 'Россия',
      city: 'г. Москва',
      street: 'Пресненская набережная, д. 8 стр. 1',
    },
  },
};
